import React, {
    Fragment, useContext, useEffect, useState,
} from 'react';
import useStyles from './Schedule.style';
import ResourcesHeader from '../../components/ScheduleHeader';
import { Box } from '@mui/material';
import AgGridComponent from '../../../../components/agGridTableComponent';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import projectQuery from '../../../../queries/schedule';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import Pagination from '../../../../components/paginationTable';
import Loader from '../../../../components/Loader';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { useDisplayCurrFormatter, formatCurr } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';
import { dateFormat } from '../../../../helper/DateFormat';

interface CellRendererType {
    colDef: {
        field: string
    }
    value: number,
}

interface Totals {
    labour_hoursTotal: number;
    labourTotal: number;
    c_equipmentTotal: number;
    materialTotal: number;
    subcontractTotal: number;
    equipmentTotal: number;
    distributableTotal: number;
    total_costTotal: number;
    sustaining_total: number;
}

function Schedule() {
    const classes = useStyles();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const formatCurrency = useDisplayCurrFormatter();
    const [searchText, setSearchText] = useState('');
    const [hideUnusedFlag, setHideUnusedFlag] = useState(true);

    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];

    const { data, isFetching, refetch } = useGQLQuery(
        'GetScheduleDataByOrgId',
        projectQuery.GET_SCHEDULE_BY_ORGID(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
            hideUnusedFlag,
        ),
    );

    useEffect(() => {
        refetch();
    }, [pageSkipValue, hideUnusedFlag]);

    const nameCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        if (params.value != null) {
            switch (colName) {
            case 'labour_hours':
                return (params.value || params.value === 0) ? formatCurr(+(+params.value).toFixed(0), local) : '-';
            case 'total_cost':
            case 'labour':
            case 'c_equipment':
            case 'material':
            case 'equipment':
            case 'distributable':
            case 'subcontract':
                return (params.value || params.value === 0) ? formatCurrency(params.value) : '-';
            case 'end_date':
                return params.value;
            case 'start_date':
                return params.value;
            default:
                return params.value;
            }
        }
        if (params.value === null) {
            return '-';
        }
        return '';
    };

    const columnDefs = [
        {
            field: 'code',
            type: 'string',
            initialWidth: 120,
            headerName: 'Code',
        },
        {
            field: 'name',
            type: 'string',
            initialWidth: 250,
            headerName: 'Package Name',
        },
        {
            field: 'start_date',
            initialWidth: 150,
            headerName: 'Start Date',
            cellRendererFramework: nameCellRenderer,
            type: 'string',
            enableValue: false,
        },
        {
            field: 'end_date',
            initialWidth: 150,
            headerName: 'End Date',
            cellRendererFramework: nameCellRenderer,
            type: 'string',
            enableValue: false,
        },
        {
            field: 'labour_hours',
            type: 'numericColumn',
            initialWidth: 190,
            headerName: 'Labour hours',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'labour',
            initialWidth: 190,
            headerName: 'Labour',
            cellRendererFramework: nameCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'c_equipment',
            type: 'numericColumn',
            initialWidth: 190,
            headerName: 'C.Equipment',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'material',
            type: 'numericColumn',
            initialWidth: 190,
            headerName: 'Material',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'subcontract',
            type: 'numericColumn',
            initialWidth: 190,
            headerName: 'Subcontract',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'equipment',
            type: 'numericColumn',
            initialWidth: 190,
            headerName: 'Equipment',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'distributable',
            type: 'numericColumn',
            initialWidth: 170,
            headerName: 'Distributables',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'total_cost',
            type: 'numericColumn',
            initialWidth: 150,
            headerName: 'Total Cost',
            cellRendererFramework: nameCellRenderer,
        },
    ];
    const totalValue: Totals = data?.schedule?.data?.total as Totals;

    const createData = (params: Totals) => [
        {
            name: 'TOTAL',
            labour_hours: params?.labour_hoursTotal,
            labour: params?.labourTotal,
            material: params?.materialTotal,
            // sustaining_cost: params?.sustaining_total,
            equipment: params?.equipmentTotal,
            subcontract: params?.subcontractTotal,
            total_cost: params?.total_costTotal,
            distributable: params?.distributableTotal,
            c_equipment: params?.c_equipmentTotal,
        },
    ];
    const pinnedBottomRowData = createData(totalValue);

    const scheduleData = data?.schedule?.data?.scheduleData;
    const pageInfo = data?.schedule?.pageInfo;

    // this function handle pagition
    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    const rowData = scheduleData && scheduleData.length ? scheduleData.map((item) => ({
        ...item,
        start_date: dateFormat(item.start_date, 'dd/mm/yyyy'),
        end_date: dateFormat(item.end_date, 'dd/mm/yyyy'),
    })) : [];

    // const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    return (
        <Fragment>
            <ResourcesHeader
                setSearchText={setSearchText}
                searchText={searchText}
                hideUnusedFlag={hideUnusedFlag}
                setHideUnusedFlag={setHideUnusedFlag}
                isScheduleFetching={isFetching}
            />
            <Box className={classes.tableSec}>
                <AgGridComponent
                    columnDefs={columnDefs}
                    rowData={rowData}
                    changeSortingValue={() => {}}
                    pinnedBottomRowData={pinnedBottomRowData}
                    isStatusBarEnabled
                    isClipboardEnabled
                    isRangeSelectable
                    isToolPanelsEnabled
                    isExportEnabled
                    moduleName="Schedule"
                    isPinnable
                    colFormat={['labour', 'c_equipment', 'material', 'subcontract', 'equipment', 'distributable', 'total_cost']}
                    footerFormat={['labour', 'c_equipment', 'material', 'subcontract', 'equipment', 'distributable', 'total_cost']}
                    disableResizable
                    quickFilterText={searchText}
                    setSearchText={setSearchText}
                />
                <Loader loading={isFetching} />
            </Box>
        </Fragment>
    );
}

export default Schedule;
