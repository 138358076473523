/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {
    useState, useEffect, useContext,
    useMemo,
    useCallback,
    useRef,
} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    ValueFormatterParams,
    IRowNode,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    FilterChangedEvent, SortChangedEvent,
    IsServerSideGroupOpenByDefaultParams,
    RowEvent,
} from 'ag-grid-community';
import { Box, IconButton } from '@mui/material';
import EstimateStyle from './Estimate.style';
import EstimateHeader from '../../components/EstimateHeader';
import estimateQueries from '../../../../queries/estimate';
import { fetchData, useGQLQuery } from '../../../../hooks/useGQLQuery';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import Pagination from '../../../../components/paginationTable';
import Loader from '../../../../components/Loader';
import { OutletContext, QueryErrorResponse } from '../../../../types/CommoditiesListType';
import {
    useOutletContext, useLocation, useNavigate, Link,
} from 'react-router-dom';
import { ProjectContext } from '../../../../store/context/projectContext';
import { UserContext } from '../../../../store/context/userContext';
import AgGridComponent from '../../../../components/agGridTableComponent';
import { CellRendererType, ColumnDefs } from '../../../../types/AgGridTypes';
import { useDisplayCurrFormatter, formatCurr, useFormatCurrency } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';
import { PATH_CMFR } from '../../../../Routes/path';
import getProjectLevel from '../../../../helper/ProjectLevel';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useQueryClient } from 'react-query';
import gqlConfig from '../../../../helper/gqlConfig';
import { EstimateType, EstimateTotalType } from '../../../../types/EstimateType';
import PageInfoType from '../../../../types/PageInfoType';
import { AgGridReact } from 'ag-grid-react';

interface EstimateDataInterface {
    description: string,
    detailed_description: string,
    quantity: number,
    ur_code: string,
    unit_cost: number,
    total_cost: number,
    wbs: {
        description: string,
        code: string,
        level: number,
        code1: string,
        code2: string
    },
    coa: {
        code: string,
        level: number,
        code1: string,
        code2: string
    }
}

interface LocationState {
    estimateFilter?: string;
    code: string;
    description: string;
}

function CustomGroupRowRenderer(params: ValueFormatterParams) {
    const { node } = params;
    const [expanded, setExpanded] = useState(node?.expanded);
    const projectCtx = useContext(ProjectContext);
    const selectedProject = projectCtx?.project;
    const casedata = projectCtx?.projectCaseData;
    const wbsLevel = getProjectLevel([
        selectedProject?.wbs1,
        selectedProject?.wbs2,
        selectedProject?.wbs3,
        selectedProject?.wbs4,
        selectedProject?.wbs5,
        selectedProject?.wbs6,
    ]);
    useEffect(() => {
        const expandListener = (event: RowEvent) => setExpanded(event.node?.expanded);

        node?.addEventListener('expandedChanged', expandListener);

        return () => {
            node?.removeEventListener('expandedChanged', expandListener);
        };
    }, []);
    const toggleExpansion = useCallback(() => node?.setExpanded(!node.expanded), [node]);
    const selectColor = () => {
        const bgColorList = ['#88B0FF', '#AECAFF', '#C1D6FF', '#dbe6fc', '#eff4ff', 'transperant'];
        let updatedColorList = [];
        if (casedata && casedata.length > 1) {
            if (wbsLevel === 1) {
                updatedColorList = bgColorList.slice(3);
            } else if (wbsLevel === 2) {
                updatedColorList = bgColorList.slice(2);
            } else if (wbsLevel === 3) {
                updatedColorList = bgColorList.slice(1);
            } else {
                updatedColorList = bgColorList;
            }
        } else if (wbsLevel === 1) {
            updatedColorList = bgColorList.slice(4);
        } else if (wbsLevel === 2) {
            updatedColorList = bgColorList.slice(3);
        } else if (wbsLevel === 3) {
            updatedColorList = bgColorList.slice(2);
        } else {
            updatedColorList = bgColorList.slice(1);
        }
        updatedColorList.reverse();
        let color;
        const [transparent, firstColor, secondColor, thirdColor] = updatedColorList;
        switch (node?.field) {
        case 'case.name':
            if (casedata && casedata.length > 1) {
                color = updatedColorList.at(-1);
            } else {
                color = 'transparent';
            }
            break;
        case 'wbs.first_parent':
            if (casedata && casedata.length > 1) {
                color = updatedColorList.at(-2);
            } else {
                color = updatedColorList.at(-1);
            }
            break;
        case 'wbs.second_parent':
            if (wbsLevel === 3) color = secondColor;
            if (wbsLevel > 3) color = thirdColor;
            if (wbsLevel < 3) color = 'transparent';
            break;
        case 'wbs.third_parent':
            if (wbsLevel > 3) color = secondColor;
            else color = 'transparent';
            break;
        case 'wbs.self_code_desc':
            color = firstColor;
            break;
        case 'coa.first_parent':
            color = `${updatedColorList[0]} !important`;
            break;
        default:
            color = '#F8F8F8';
            break;
        }
        return color;
    };

    const style = {
        height: '100%',
        backgroundColor: selectColor(),
        fontSize: '12px',
        fontWeight: 700,
        color: '#000',
        lineHeight: '27px',
        paddingLeft: '22px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    };

    return (
        <div
            style={style}
            onClick={toggleExpansion}
            onKeyDown={toggleExpansion}
            role="button"
            tabIndex={0}
        >
            {node?.group && (
                <div
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={toggleExpansion}
                    onKeyDown={toggleExpansion}
                    role="button"
                    tabIndex={0}
                    aria-label="Toggle Button"
                >
                    <KeyboardArrowRightIcon
                        style={{
                            transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                        }}
                        onClick={toggleExpansion}
                    />
                </div>
            )}
            &nbsp;
            {node?.key || node?.parent?.key || node?.parent?.parent?.key || node?.parent?.parent?.parent?.key}
        </div>
    );
}

interface Data {
    estimatedData: EstimateType[];
    total: EstimateTotalType;
}

interface ResponseEstimateDetail {
    estimate_detail?: {
        data?: {
          estimatedData: EstimateType[];
          total: EstimateTotalType;
        };
        pageInfo: PageInfoType;
    };
}

interface ColumnDefsButWithSort extends ColumnDefs {
    initialSort?: 'asc' | 'desc' | null,
    initialRowGroup?: boolean,
    initialSortIndex?: number
}

export default function Estimate() {
    const ref = useRef<AgGridReact>(null);
    const classes = EstimateStyle();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const cont: OutletContext = useOutletContext();
    const projectCtx = useContext(ProjectContext);
    const userCtx = useContext(UserContext);
    const formatCurrency = useDisplayCurrFormatter();
    const currenctFormat = useFormatCurrency();
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate;
    const loadingRenderer = true;
    const [searchText, setSearchText] = useState<string>('');
    const debouncingValue = useDebounce(searchText);
    const [stateText, setStateText] = useState<string>('false');
    const debouncedText = useDebounce(stateText);
    const [filters, setFilters] = useState<string>('');
    const [sortModel, setSortModel] = useState<string>('');
    const [rowGroupModel, setRowGroupModel] = useState<string>('[]');
    const [groupKeys, setGroupKeys] = useState<string>('');
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();
    const { estimateFilter, code, description } = (location.state || {}) as LocationState;
    const selectedProject = projectCtx?.project;
    const local:string = CURR_FORMAT[selectedProject?.currency || 'USD'];

    const handleLink = (unitRateId: string) => {
        navigate(PATH_CMFR.capex.unit, { state: { unitRateId } });
    };

    const rowCellRenderer = (params: CellRendererType) => {
        const { value } = params;
        const colName = params.colDef.field;
        if (value != null) {
            if (params?.node?.rowPinned) {
                return (
                    <span>
                        {params?.value.toLocaleString()}
                    </span>
                );
            }
            if (value && params.colDef.isDelimiter) {
                return formatCurr(+value.toFixed(0), local);
            }

            switch (colName) {
            case 'total_cost':
                return params.value || params.value === 0 ? formatCurrency(params.value.toFixed(0)) : '-';
            case 'total_unit_cost':
            case 'unit_labour_cost':
            case 'unit_consequip_cost':
            case 'unit_material_cost':
            case 'unit_subcontract_cost':
            case 'unit_indirect_cost':
            case 'labour_cost':
            case 'construction_equip_cost':
            case 'material_cost':
            case 'permanent_equip_cost':
            case 'subcontract_cost':
            case 'indirect_cost':
            case 'unit_pequipment_cost':
                return params.value || params.value === 0 ? formatCurrency(params.value.toFixed(2)) : '-';
            case 'quantity':
                return params.value || params.value === 0 ? formatCurr(params.value.toFixed(0), local) : '-';
            case 'description':
                return (
                    <Link
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLink(params?.data?.unit_rate_id);
                        }}
                        // className={classes.boldText}
                    >
                        {params?.value.toLocaleString()}
                    </Link>
                );
            case 'markup_labour':
            case 'markup_construction_equip':
            case 'markup_material':
            case 'markup_permanent_equip':
            case 'markup_subcontract':
                return params.value || params.value === 0 ? formatCurr(value.toFixed(2), local) : '-';
            default:
                return value;
            }
        } else if (value === null) {
            return '-';
        }
        return '';
    };

    const columnDefs: ColumnDefsButWithSort[] = [
        {
            field: 'wbs.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'WBS',
            cellRenderer: rowCellRenderer,
            initialSort: 'asc',
            initialSortIndex: 2,
        },
        {
            field: 'coa.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'COA',
            // initialRowGroup: true,
            cellRenderer: rowCellRenderer,
            suppressMenu: false,
            sortable: true,
            initialSort: 'asc',
            initialSortIndex: 3,
        },
        {
            field: 'case.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'Case',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            suppressMenu: false,
            sortable: true,
            rowGroup: false,
            initialSort: 'asc',
            initialSortIndex: 1,
        },
        {
            field: 'phase.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'Phase',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            initialSort: 'asc',
            initialSortIndex: 4,
        },
        {
            field: 'phase.name',
            type: 'string',
            initialWidth: 130,
            headerName: 'Phase Desc',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_labour.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'Lab Pkg',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_construction.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'Ceq Pkg',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_material.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'Mat Pkg',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_permanent.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'Eqp Pkg',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_subcontract.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'Sub Pkg',
            // initialRowGroup: true,
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_indirect.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'Ind Pkg',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_rate.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'Unit Rate',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'wbs.code1',
            type: 'string',
            initialWidth: 130,
            headerName: 'WBS Code1',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            colId: 'wbs.code1',
            // valueFormatter: wbsCodeValueFormatter,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'item_type',
            type: 'string',
            initialWidth: 130,
            headerName: 'Type',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'wbs.code2',
            type: 'string',
            initialWidth: 130,
            headerName: 'WBS Code2',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            // valueFormatter: wbsCodeValueFormatter,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'wbs.code3',
            type: 'string',
            initialWidth: 130,
            headerName: 'WBS Code3',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            colId: 'wbs.code3',
            // valueFormatter: wbsCodeValueFormatter,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'wbs.code4',
            type: 'string',
            initialWidth: 130,
            headerName: 'WBS Code4',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            colId: 'wbs.code4',
            // valueFormatter: wbsCodeValueFormatter,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
        },
        {
            field: 'sequence',
            type: 'string',
            initialWidth: 130,
            headerName: 'Item',
            cellRenderer: rowCellRenderer,
            initialSort: 'asc',
            initialSortIndex: 5,
        },
        {
            field: 'description',
            type: 'string',
            initialWidth: 300,
            headerName: 'Description1',
            cellRenderer: rowCellRenderer,
            // cellRendererFramework: rowCellRenderer,
        },
        {
            field: 'detailed_description',
            type: 'string',
            initialWidth: 300,
            headerName: 'Description2',
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'quantity',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Quantity',
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'ur_code',
            type: 'string',
            initialWidth: 160,
            headerName: 'Unit Rate',
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'base_quantity',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Base Quantity',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            isDelimiter: true,
        },
        {
            field: 'design_growth',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Design Growth',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            isDelimiter: true,
        },
        {
            field: 'prod_factor',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Prod Factor',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            isDelimiter: true,
        },
        {
            field: 'wastage_factor',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Wastage',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            isDelimiter: true,
        },
        {
            field: 'markup_labour',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Lab Markup',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            // isDelimiter: true,
        },
        {
            field: 'markup_construction_equip',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Ceq Markup',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            // isDelimiter: true,
        },
        {
            field: 'markup_material',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Mat Markup',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            // isDelimiter: true,
        },
        {
            field: 'markup_permanent_equip',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Eqp Markup',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            // isDelimiter: true,
        },
        {
            field: 'markup_subcontract',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Sub Markup',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            // isDelimiter: true,
        },
        {
            field: 'unit_man_hours',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Unit Man Hours',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            isDelimiter: true,
        },
        {
            field: 'unit_labour_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Unit Labour',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_consequip_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Unit C.Equip',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_material_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Unit Material',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_pequipment_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Unit Equipment',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_subcontract_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Unit Subcontract',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_indirect_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Unit Indierct',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'labour_hours',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Labour Hours',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            isDelimiter: true,
        },
        {
            field: 'labour_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Labour',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'construction_equip_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'C.Equiment',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'material_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Material',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'permanent_equip_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Equipment',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'subcontract_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Subcontract',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'indirect_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Indirect',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'total_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Total Cost',
            cellRenderer: rowCellRenderer,
        },
        {

            field: 'total_unit_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Unit Cost',
            rowGroup: false,
            cellRenderer: rowCellRenderer,
            suppressToolPanel: false,
            cellRendererParams: {
                suppressCount: false,
            },
        },
    ];

    const [newColumnDefs, setNewColumnDefs] = useState<ColumnDefsButWithSort[]>(columnDefs);
    // const handleApiError = ({ response }: QueryErrorResponse) => {
    //     const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
    //     cont.showNotificationBar(message, 'error');
    // };

    // const { data, isFetching, refetch } = useGQLQuery(
    //     `GetEstimateDetails-${selectedProject?.id || ''}`,
    //     estimateQueries.GET_ESTIMATE_DETAILS(
    //         pageSkipValue,
    //         DEFAULT_PAGE_SIZE,
    //         userCtx?.user?.default_org_id || '',
    //         selectedProject?.id || '',
    //         selectedProject?.version_id || '',
    //         estimateFilter || '',
    //         debouncingValue,
    //         filters,
    //         sortModel,
    //         rowGroupModel,
    //         groupKeys,
    //         exchangeRate || 1,
    //     ),
    //     {},
    //     {
    //         onError: handleApiError,
    //     },
    // );

    // const estimateData = data?.estimate_detail;
    // const estData = estimateData?.data;
    // const tableData = estData?.estimatedData;
    // const totalValue = estData?.total;
    // const pageInfo = estimateData?.pageInfo;
    // const rowData = tableData && tableData.length ? tableData : [];
    // const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    // let footerData;
    // if (totalValue) {
    //     footerData = [{
    //         // wbs: 'Selected(0)',
    //         description: `Total Man Hours : ${totalValue?.total_man_hours?.toFixed(0)}`,
    //         detailed_description: `L Cost : ${(currenctFormat(totalValue?.labour_cost?.toFixed(0)))}`,
    //         quantity: `CE Cost : ${currenctFormat(totalValue?.construction_equip_cost?.toFixed(0))}`,
    //         ur_code: `M Cost : ${currenctFormat(totalValue?.material_cost?.toFixed(0))}`,
    //         total_cost: `E Cost : ${currenctFormat(totalValue?.equipment_cost?.toFixed(0))}`,
    //         total_unit_cost: `T Cost : ${currenctFormat(totalValue?.total_cost?.toFixed(0))}`,
    //     }];
    // }

    const handlePageChange = (page: number) => {
        const skip = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skip);
    };

    const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        // refetch({ cancelRefetch: isFetching });
    }, [pageSkipValue]);

    const [data, setData] = useState<Data | null>(null);
    const addColsForAttr = (estimates: Data) : Data => {
        const estData = JSON.parse(JSON.stringify(estimates)) as Data;
        if (estData?.estimatedData && estData?.estimatedData?.length) {
            estData?.estimatedData?.forEach((dt: EstimateType, index: number) => {
                const d = JSON.parse(JSON.stringify(dt)) as EstimateType;
                if (d.labels && typeof d.labels === 'string') {
                    d.labels = JSON.parse(d.labels);
                    if (typeof d.labels === 'object' && d.labels !== null) {
                        let labels = {};
                        labels = d.labels;
                        estData.estimatedData[index] = { ...d, ...labels };
                    }
                }
            });
            setData(() => estData);
        }
        return estData;
    };

    const [canRender, setCanRender] = useState<boolean>(false);
    useEffect(() => {
        const isWbsPresent = newColumnDefs.find((cols) => cols.field === 'wbs.self_code_desc');
        if (isWbsPresent) {
            setCanRender(() => true);
        }
    }, [newColumnDefs]);

    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [footerData, setFooterData] = useState<string | null>(null);
    const [count, setCount] = useState<number>(0);
    const getRows = useCallback((params: IServerSideGetRowsParams) => {
        // if (JSON.stringify(params.request.rowGroupCols) === '[]') {
        //     void queryClient.cancelQueries([`GetEstimateDetails-${selectedProject?.id || ''}`]);
        // }
        setIsFetching(() => true);
        setCount((prev) => prev + 1);
        if (canRender
            && projectCtx?.projectCaseData
            && params?.request?.rowGroupCols
            && params?.request?.groupKeys
            && searchText === debouncingValue
            && debouncedText === stateText) {
            const sortingModel = params.request.sortModel.map((col) => ({ colName: col.colId, sort: col.sort }));
            const query = estimateQueries.GET_ESTIMATE_DETAILS(
                params?.request?.startRow || 0,
                100,
                userCtx?.user?.default_org_id || '',
                selectedProject?.id || '',
                selectedProject?.version_id || '',
                estimateFilter || '',
                debouncingValue,
                filters,
                JSON.stringify(sortingModel),
                JSON.stringify(params.request.rowGroupCols),
                JSON.stringify(params.request.groupKeys),
                exchangeRate || 1,
            );

            const graphQLClient = gqlConfig('/list');
            setIsFetching(() => true);
            fetchData(graphQLClient, query, {}).then((unknownResponse: unknown) => {
                const response = unknownResponse as ResponseEstimateDetail;
                const attrData = addColsForAttr(response?.estimate_detail?.data as Data);
                const totalValue = attrData?.total;
                if (totalValue) {
                    const footerValue = [{
                        // wbs: 'Selected(0)',
                        labour_hours: ` ${totalValue?.total_man_hours === null ? '-' : (+totalValue.total_man_hours.toFixed(0)).toLocaleString()}`,
                        labour_cost: `${totalValue?.labour_cost === null ? '-'
                            : currenctFormat(totalValue?.labour_cost?.toFixed(0))}`,
                        construction_equip_cost: `${totalValue?.construction_equip_cost === null ? '-'
                            : currenctFormat(totalValue?.construction_equip_cost?.toFixed(0))}`,
                        material_cost: `${totalValue?.material_cost === null ? '-' : currenctFormat(totalValue?.material_cost?.toFixed(0))}`,
                        permanent_equip_cost: `${totalValue?.equipment_cost === null ? '-' : currenctFormat(totalValue?.equipment_cost?.toFixed(0))}`,
                        total_cost: `${totalValue?.total_cost === null ? '-' : currenctFormat(totalValue?.total_cost?.toFixed(0))}`,
                    }];
                    setFooterData(JSON.stringify(footerValue));
                } else {
                    setFooterData(null);
                }
                const rowsData = attrData?.estimatedData && attrData?.estimatedData.length ? attrData?.estimatedData : [];
                const totalRows = response?.estimate_detail?.pageInfo?.totalcount;
                params.successCallback(rowsData, totalRows || rowsData.length);
                if (!rowsData.length) {
                    ref?.current?.api?.showNoRowsOverlay();
                }
            }).catch((err: Error) => {
                params.failCallback();
                cont.showNotificationBar(err.message.substring(0, 60), 'error');
            }).finally(() => {
                setIsFetching(() => false);
                setCount((prev) => prev - 1);
            });

            if (JSON.stringify(params.request.rowGroupCols) !== rowGroupModel) setRowGroupModel(JSON.stringify(params.request.rowGroupCols));
            if (JSON.stringify(params.request.groupKeys) !== groupKeys) setGroupKeys(JSON.stringify(params.request.groupKeys));
        } else {
            params.failCallback();
            setIsFetching(() => false);
            setCount((prev) => prev - 1);
        }
    }, [filters, debouncingValue, searchText, debouncedText, stateText, canRender]);

    const datasource: IServerSideDatasource = useMemo(() => ({
        getRows,
    }), [getRows]);

    const onFilterChanges = (event: FilterChangedEvent) => {
        if (filters !== JSON.stringify(event.api.getFilterModel())) {
            setIsFetching(() => true);
        }
        setFilters(JSON.stringify(event.api.getFilterModel()));
    };

    const onSortChanges = (event: SortChangedEvent) => {
        const sortingModel = event.columnApi.getColumnState().reduce((acc: { colName: string; sort: string; }[], col) => {
            if (col.sort) {
                acc.push({ colName: col.colId, sort: col.sort });
            }
            return acc;
        }, []);
        setSortModel(JSON.stringify(sortingModel));
    };

    const groupsOpenByDefault = (
        params: IsServerSideGroupOpenByDefaultParams,
    ) => {
        if (!estimateFilter) return false;
        return params.rowNode.level >= 0 && params.rowNode.level < ((JSON.parse(rowGroupModel) as unknown[]).length - 1);
    };

    useEffect(() => {
        if (ref && ref?.current) {
            if (debouncingValue) {
                setSearchText(() => '');
                setTimeout(() => {
                    ref?.current?.columnApi?.resetColumnState();
                    ref?.current?.api?.setFilterModel(null);
                });
            } else {
                setStateText((prev) => (prev === 'false' ? 'true' : 'false'));
                setTimeout(() => {
                    ref?.current?.columnApi?.resetColumnState();
                    if (filters && filters !== '{}') {
                        ref?.current?.api?.setFilterModel(null);
                    } else {
                        ref?.current?.api?.setServerSideDatasource(datasource);
                    }
                });
            }
        }
    }, [exchangeRate]);

    useEffect(() => {
        ref?.current?.api?.deselectAll();
    }, [exchangeRate, debouncingValue, filters, sortModel]);

    const wbsparent1 = {
        field: 'wbs.first_parent',
        type: 'string',
        initialWidth: 130,
        headerName: 'WBS Parent 1',
        initialRowGroup: true,
        suppressMenu: true,
        sortable: false,
        cellRenderer: rowCellRenderer,
        initialHide: true,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
    };
    const wbsparent2 = {
        field: 'wbs.second_parent',
        type: 'string',
        initialWidth: 130,
        headerName: 'WBS Parent 2',
        initialRowGroup: true,
        suppressMenu: true,
        sortable: false,
        cellRenderer: rowCellRenderer,
        initialHide: true,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
    };
    const wbsparent3 = {
        field: 'wbs.third_parent',
        type: 'string',
        initialWidth: 130,
        headerName: 'WBS Parent 3',
        initialRowGroup: true,
        suppressMenu: true,
        sortable: false,
        cellRenderer: rowCellRenderer,
        initialHide: true,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
    };
    const wbsparent4 = {
        field: 'wbs.forth_parent',
        type: 'string',
        initialWidth: 160,
        headerName: 'WBS Parent 4',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
    };
    const wbsparent5 = {
        field: 'wbs.fifth_parent',
        type: 'string',
        initialWidth: 160,
        headerName: 'WBS Parent 5',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
    };
    const selfwbs = {
        field: 'wbs.self_code_desc',
        type: 'string',
        initialWidth: 130,
        headerName: 'WBS Self Desc',
        initialRowGroup: true,
        suppressMenu: true,
        sortable: false,
        cellRenderer: rowCellRenderer,
        initialHide: true,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
    };
    const coaParent1 = {
        field: 'coa.first_parent',
        type: 'string',
        initialWidth: 130,
        headerName: 'COA Parent 1',
        initialRowGroup: true,
        suppressMenu: true,
        sortable: false,
        cellRenderer: rowCellRenderer,
        initialHide: true,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
    };
    const coaParent2 = {
        field: 'coa.second_parent',
        type: 'string',
        initialWidth: 160,
        headerName: 'COA Parent 2',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
    };
    const coaParent3 = {
        field: 'coa.third_parent',
        type: 'string',
        initialWidth: 160,
        headerName: 'COA Parent 3',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
    };
    const coaParent4 = {
        field: 'coa.forth_parent',
        type: 'string',
        initialWidth: 160,
        headerName: 'COA Parent 4',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
    };
    const coaParent5 = {
        field: 'coa.fifth_parent',
        type: 'string',
        initialWidth: 160,
        headerName: 'COA Parent 5',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
    };

    const [newAttrCols, setNewAttrCols] = useState<ColumnDefsButWithSort[]>([]);
    useEffect(() => {
        const colsToPush: ColumnDefsButWithSort[] = [];
        const casedata = projectCtx?.projectCaseData;
        const projectLevel = getProjectLevel([
            selectedProject?.wbs1,
            selectedProject?.wbs2,
            selectedProject?.wbs3,
            selectedProject?.wbs4,
            selectedProject?.wbs5,
            selectedProject?.wbs6,
        ]);
        const coaProjectLevel = getProjectLevel([
            selectedProject?.coa1,
            selectedProject?.coa2,
            selectedProject?.coa3,
            selectedProject?.coa4,
            selectedProject?.coa5,
            selectedProject?.coa6,
        ]);

        if (casedata && casedata.length > 1) {
            colsToPush.push({
                field: 'case.name',
                type: 'numericColumn',
                initialWidth: 130,
                headerName: 'Case Desc',
                cellRenderer: rowCellRenderer,
                initialHide: true,
                initialRowGroup: true,
                suppressMenu: true,
                sortable: false,
                cellRendererParams: {
                    suppressCount: true,
                },
                suppressToolPanel: true,
            });
        } else {
            colsToPush.push({
                field: 'case.name',
                type: 'numericColumn',
                initialWidth: 130,
                headerName: 'Case Desc',
                cellRenderer: rowCellRenderer,
                initialHide: true,
            });
        }

        switch (projectLevel) {
        case 1:
            colsToPush.push(selfwbs);
            break;
        case 2:
            colsToPush.push(wbsparent1, selfwbs);
            break;
        case 3:
            colsToPush.push(
                wbsparent1,
                wbsparent2,
                selfwbs,
            );
            break;
        case 4:
            colsToPush.push(
                wbsparent1,
                wbsparent2,
                wbsparent3,
                selfwbs,
            );
            break;
        case 5:
            colsToPush.push(
                wbsparent1,
                wbsparent2,
                wbsparent3,
                wbsparent4,
                selfwbs,
            );
            break;
        default:
            colsToPush.push(
                wbsparent1,
                wbsparent2,
                wbsparent3,
                wbsparent4,
                wbsparent5,
                selfwbs,
            );
        }
        switch (coaProjectLevel) {
        case 1:
            colsToPush.push(coaParent1);
            break;
        case 2:
            colsToPush.push(coaParent1);
            break;
        case 3:
            colsToPush.push(coaParent1, coaParent2);
            break;
        case 4:
            colsToPush.push(coaParent1, coaParent2, coaParent3);
            break;
        case 5:
            colsToPush.push(coaParent1, coaParent2, coaParent3, coaParent4);
            break;
        default:
            colsToPush.push(coaParent1, coaParent2, coaParent3, coaParent4, coaParent5);
        }
        if (estimateFilter) {
            const colsForWbs = colsToPush.map((col: ColumnDefsButWithSort) => {
                const column = JSON.parse(JSON.stringify(col)) as ColumnDefsButWithSort;
                if (col.field !== 'wbs.self_code_desc' && col.field !== 'coa.first_parent') {
                    column.initialRowGroup = false;
                }
                return column;
            });
            columnDefs.push(...colsForWbs, ...newAttrCols);
        } else {
            columnDefs.push(...colsToPush, ...newAttrCols);
        }
        setTimeout(() => {
            setNewColumnDefs(columnDefs);
        });
        if (!isFetching && data?.estimatedData && data?.estimatedData?.length) {
            if (data?.estimatedData?.length > 0 && data?.estimatedData[0]?.labels) {
                Object.entries(data.estimatedData[0].labels).forEach(([key]) => {
                    if (key !== 'null' && !(columnDefs.some((col) => col.field === key))) {
                        const obj = {
                            field: key,
                            headerName: `${key}`,
                            cellRenderer: rowCellRenderer,
                            initialWidth: 150,
                            type: 'string',
                            initialHide: true,
                            suppressMenu: true,
                            sortable: false,
                            rowGroup: false,
                            suppressFiltersToolPanel: true,
                        };
                        columnDefs.push(obj);
                        newAttrCols.push(obj);
                    }
                });
                setNewAttrCols(newAttrCols);
                setTimeout(() => {
                    setNewColumnDefs(columnDefs);
                });
            }
        }
    }, [isFetching, count]);

    const [loader, setLoader] = useState<boolean>(true);
    useEffect(() => {
        if (count === 0) {
            setLoader(() => false);
        } else {
            setLoader(() => true);
        }
    }, [count]);

    // const sortedData = rowData.sort((a, b) => +(b.wbs.code) - +(a.wbs.code));

    return (
        <Box className={estimateFilter ? classes.mainEstmate : classes.mainEstmate2}>

            <Loader loading={loader} />
            <EstimateHeader
                setSearchText={setSearchText}
                searchText={searchText}
            />
            { estimateFilter
            && (
                <Box className={classes.filterDesc}>
                    <span>Data filtered by: </span>
                    <span>
                        {code}
                        {' '}
                        -
                        {' '}
                        {description}
                    </span>
                </Box>
            )}
            <Box>
                <Box className={loader ? classes.opacityBlur : ''}>
                    <AgGridComponent
                        gridRef={ref}
                        columnDefs={newColumnDefs}
                        // rowData={rowData}
                        changeSortingValue={() => { }}
                        pinnedBottomRowData={footerData ? JSON.parse(footerData) : undefined}
                        // quickFilterText={searchText}
                        isPinnable
                        isGroupable
                        isRangeSelectable
                        isExportEnabled
                        isClipboardEnabled
                        isToolPanelsEnabled
                        isStatusBarEnabled
                        moduleName="estimate"
                        defaultExpanded={6}
                        isRangeHandle
                        customGroupRowRenderer={CustomGroupRowRenderer}
                        colFormat={['total_cost', 'total_unit_cost', 'unit_labour_cost',
                            'unit_consequip_cost', 'unit_material_cost', 'unit_pequipment_cost',
                            'unit_subcontract_cost', 'unit_indirect_cost', 'labour_cost', 'construction_equip_cost',
                            'material_cost', 'permanent_equip_cost', 'subcontract_cost', 'indirect_cost']}
                        disableResizable
                        datasource={datasource}
                        onFilterChanges={onFilterChanges}
                        onSortChanges={onSortChanges}
                        loadingRenderer={loadingRenderer}
                        groupsOpenByDefault={groupsOpenByDefault}
                        setSearchText={setSearchText}
                        isMultiFilterRequired={false}
                    />
                </Box>
            </Box>
        </Box>
    );
}
